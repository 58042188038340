<template>
	<div class="page_box">

		<div class="top_box">
			<div class="sec-box sec-number">
				<div class="panel-item">
					<div class="sec-title">
						岗位人数统计
					</div>
					<div class="sec-ctx">
						<ve-histogram data-type="发货数据图表" height="500px" :data="demo_histogram.chartData"
							:theme="demo_histogram.theme" :extend="demo_histogram.extend_config"></ve-histogram>
					</div>
				</div>
			</div>

			<div class="sec-box sec-age-edu-year">
				<div class="panel-list">
					<div class="panel-item">
						<div class="sec-title">
							年龄统计
						</div>
						<div class="sec-ctx">
							<ve-pie height="455px" data-title="饼状图" :data="demo1_pie.chartData" :theme="demo1_pie.theme"
								:extend="demo1_pie.extend_config"></ve-pie>
						</div>
					</div>

					<div class="panel-item">
						<div class="sec-title">
							学历统计
						</div>
						<div class="sec-ctx">
							<ve-pie height="455px" data-title="饼状图" :data="demo2_pie.chartData" :theme="demo2_pie.theme"
								:extend="demo2_pie.extend_config"></ve-pie>
						</div>
					</div>

					<div class="panel-item">
						<div class="sec-title">
							司龄统计
						</div>
						<div class="sec-ctx">
							<ve-pie height="455px" data-title="饼状图" :data="demo3_pie.chartData" :theme="demo3_pie.theme"
								:extend="demo3_pie.extend_config"></ve-pie>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					<!-- {{ page_title }} -->
					数据统计
				</div>
				<div class="rights">
					<!-- 		<div class="btns">
						导出礼品数据
					</div> -->

				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading">
					<el-table-column prop="title" label="岗位名称"></el-table-column>
					<el-table-column prop="num" label="定额人数"></el-table-column>
					<el-table-column prop="zg_num" label="在岗人数"></el-table-column>
					<el-table-column prop="nl_num" label="平均年龄"></el-table-column>
					<el-table-column prop="sl_num" label="平均司龄"></el-table-column>
				</el-table>
				<!-- <div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div> -->
			</div>
		</div>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	export default {
		components: {
			page,
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					identity: '',
				},
				list: [{
						name: '123123'
					},

				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围


				// 柱状图
				demo_histogram: {
					type: "柱状图",
					chartData: {
						columns: ["岗位", "定额人数", "在岗人数"],
						rows: [
							// {
							// 	岗位: "岗位1",
							// 	定额人数: 0,
							// 	在岗人数: 100
							// },
							// {
							// 	岗位: "岗位1",
							// 	定额人数: 0,
							// 	在岗人数: 150
							// },
							// {
							// 	岗位: "岗位3",
							// 	定额人数: 492,
							// 	在岗人数: 492
							// },
						],
					},
					grid: {
						left: "0",
						right: "0",
					},
					theme: {
						line: {
							smooth: true
						}
					},
					extend_config: {
						color: [
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						legend: {
							//
							show: true,
							bottom: 0,
							data: [{
								name: "定额人数"
							}, {
								name: "在岗人数"
							}],
						},

						series(arr) {
							arr.forEach((sub, sub_index) => {
								// sub.barGap = "50%";
								sub.label = {
									show: true,
									position: 'top',
									color: '#000000'
								};
								sub.barCategoryGap = "50%"; //柱宽度
								// sub.label = {
								//     color: "#000",
								//     show: true,
								//     position: "top",
								//   };
							});
							return arr;
						},

					},
				},


				//图表数据
				demo1_pie: {
					type: "饼状图",
					chartData: {
						columns: ["维度", "人数"],
						rows: [
							// {
							// 	维度: "25-35岁",
							// 	人数: 10
							// },
							// {
							// 	维度: "35-45岁",
							// 	人数: 20
							// },
							// {
							// 	维度: "45-55岁",
							// 	人数: 30
							// },
							// {
							// 	维度: "55-65岁",
							// 	人数: 40
							// },
						],
					},
					theme: {
						line: {
							smooth: false
						}
					},
					extend_config: {
						color: [
							//
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						grid: {
							left: "0%",
							right: "0%",
							top: "0%",
							bottom: '0%',
						},
						legend: {
							show: true,
							bottom: 0,
							data: [
							// 	{
							// 	name: "25-35岁"
							// }, {
							// 	name: "35-45岁"
							// }, {
							// 	name: "45-55岁"
							// }, {
							// 	name: "55-65岁"
							// },
							],
						},
						series(arr) {
							console.log('series arr', arr)

							arr.forEach((sub, sub_index) => {
								sub.label = {
									show: true,
									color: '#000000',
									formatter: (params) => {
										//console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										// return value + '人'
										return `${value}人 (占比${percent} %)`
									}
								};

								sub.tooltip = {
									formatter: (params) => {
										console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										return `${name}: ${value}人 (占比${percent} %)`

									}
								}

							});
							return arr;
						},
					},
				},
				//图表数据



				//图表数据
				demo2_pie: {
					type: "饼状图",
					chartData: {
						columns: ["学历", "人数"],
						rows: [{
								学历: "大专",
								"人数": "10"
							},
							{
								学历: "本科",
								"人数": "20"
							},
							{
								学历: "研究生",
								"人数": "30"
							},
							{
								学历: "博士生",
								"人数": "40"
							},
						],
					},
					theme: {
						line: {
							smooth: false
						}
					},
					extend_config: {
						color: [
							//
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						grid: {
							left: "0%",
							right: "0%",
							top: "0%",
							bottom: '0%',
						},
						legend: {
							show: true,
							bottom: 0,
							data: [{
								name: "大专"
							}, {
								name: "本科"
							}, {
								name: "研究生"
							}, {
								name: "博士生"
							}],
						},
						series(arr) {
							console.log('series arr', arr)

							arr.forEach((sub, sub_index) => {
								sub.label = {
									show: true,
									color: '#000000',
									formatter: (params) => {
										//console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										// return value + '人'
										return `${value}人 (占比${percent} %)`
									}
								};

								sub.tooltip = {
									formatter: (params) => {
										console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										return `${name}: ${value}人 (占比${percent} %)`

									}
								}

							});
							return arr;
						},

					},
				},
				//图表数据


				//图表数据
				demo3_pie: {
					type: "饼状图",
					chartData: {
						columns: ["司龄", "人数"],
						rows: [{
								司龄: "1-3年",
								"人数": "10"
							},
							{
								司龄: "3-6年",
								"人数": "20"
							},
							{
								司龄: "6-9年",
								"人数": "30"
							},
							{
								司龄: "9年以上",
								"人数": "40"
							},
						],
					},
					theme: {
						line: {
							smooth: false
						}
					},
					extend_config: {
						color: [
							//
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						grid: {
							left: "0%",
							right: "0%",
							top: "0%",
							bottom: '0%',
						},
						legend: {
							show: true,
							bottom: 0,
							data: [{
								name: "1-3年"
							}, {
								name: "3-6年"
							}, {
								name: "6-9年"
							}, {
								name: "9年以上"
							}],
						},
						series(arr) {
							console.log('series arr', arr)

							arr.forEach((sub, sub_index) => {
								sub.label = {
									show: true,
									color: '#000000',
									formatter: (params) => {
										//console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										// return value + '人'
										return `${value}人 (占比${percent} %)`
									}
								};

								sub.tooltip = {
									formatter: (params) => {
										console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										return `${name}: ${value}人 (占比${percent} %)`

									}
								}

							});
							return arr;
						},
					},
				},
				//图表数据

			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.setView()
		},
		mounted() {

		},
		methods: {
			setView() {
				//用户 岗位人数统计 图表数据
				//用户 底部表格 人数数据
				this.$api("getStatistics", {
					page: 1,
					limit: 1000,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.list = res.data.list
						this.set_demo_histogram()
						
						this.set_demo_pie1(res.data.age_arr)
						this.set_demo_pie2(res.data.edu_arr)
						this.set_demo_pie3(res.data.sl_arr)
					}
				});
			},
	
			set_demo_histogram() {
				let rows = this.list.map(v => ({
						岗位: v.title,
						定额人数: v.num,
						在岗人数: v.zg_num
				}))
				let demo_histogram = {
					type: "柱状图",
					chartData: {
						columns: ["岗位", "定额人数", "在岗人数"],
						rows: rows || [
							// {
							// 	岗位: "岗位1",
							// 	定额人数: 0,
							// 	在岗人数: 100
							// },
							// {
							// 	岗位: "岗位1",
							// 	定额人数: 0,
							// 	在岗人数: 150
							// },
						],
					},
					grid: {
						left: "0",
						right: "0",
					},
					theme: {
						line: {
							smooth: true
						}
					},
					extend_config: {
						color: [
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						legend: {
							//
							show: true,
							bottom: 0,
							data: [{
								name: "定额人数"
							}, {
								name: "在岗人数"
							}],
						},

						series(arr) {
							arr.forEach((sub, sub_index) => {
								// sub.barGap = "50%";
								sub.label = {
									show: true,
									position: 'top',
									color: '#000000'
								};
								sub.barCategoryGap = "50%"; //柱宽度
								// sub.label = {
								//     color: "#000",
								//     show: true,
								//     position: "top",
								//   };
							});
							return arr;
						},

					},
				}
				this.demo_histogram = demo_histogram;
			},
			
			
			
			set_demo_pie1(infos) {
				let rows = [];
				let legend_data = []
				for(var key in infos) {
					//数据列
					let item = {
							维度: key,
							人数: infos[key]
					}
					rows.push(item)
					
					
					//图例
					legend_data.push({name: key})
				}
				
				
				let demo1_pie = {
					type: "饼状图",
					chartData: {
						columns: ["维度", "人数"],
						rows: rows || [
							// {
							// 	维度: "25-35岁",
							// 	人数: 10
							// },
							// {
							// 	维度: "35-45岁",
							// 	人数: 20
							// },
							// {
							// 	维度: "45-55岁",
							// 	人数: 30
							// },
							// {
							// 	维度: "55-65岁",
							// 	人数: 40
							// },
						],
					},
					theme: {
						line: {
							smooth: false
						}
					},
					extend_config: {
						color: [
							//
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						grid: {
							left: "0%",
							right: "0%",
							top: "0%",
							bottom: '0%',
						},
						legend: {
							show: true,
							bottom: 0,
							data: legend_data ||  [
							// 	{
							// 	name: "25-35岁"
							// }, {
							// 	name: "35-45岁"
							// }, {
							// 	name: "45-55岁"
							// }, {
							// 	name: "55-65岁"
							// },
							],
						},
						series(arr) {
							console.log('series arr', arr)

							arr.forEach((sub, sub_index) => {
								sub.label = {
									show: true,
									color: '#000000',
									formatter: (params) => {
										//console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										// return value + '人'
										return `${value}人 (占比${percent} %)`
									}
								};

								sub.tooltip = {
									formatter: (params) => {
										console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										return `${name}: ${value}人 (占比${percent} %)`

									}
								}

							});
							return arr;
						},
					},
				}
				
				this.demo1_pie  = demo1_pie;
			},
			
			
			
			set_demo_pie2(infos) {
				let rows = [];
				let legend_data = []
				for(var key in infos) {
					//数据列
					let item = {
							维度: key,
							人数: infos[key]
					}
					rows.push(item)
					
					
					//图例
					legend_data.push({name: key})
				}
				
				
				let demo2_pie = {
					type: "饼状图",
					chartData: {
						columns: ["维度", "人数"],
						rows: rows || [
							// {
							// 	维度: "25-35岁",
							// 	人数: 10
							// },
							// {
							// 	维度: "35-45岁",
							// 	人数: 20
							// },
							// {
							// 	维度: "45-55岁",
							// 	人数: 30
							// },
							// {
							// 	维度: "55-65岁",
							// 	人数: 40
							// },
						],
					},
					theme: {
						line: {
							smooth: false
						}
					},
					extend_config: {
						color: [
							//
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						grid: {
							left: "0%",
							right: "0%",
							top: "0%",
							bottom: '0%',
						},
						legend: {
							show: true,
							bottom: 0,
							data: legend_data ||  [
							// 	{
							// 	name: "25-35岁"
							// }, {
							// 	name: "35-45岁"
							// }, {
							// 	name: "45-55岁"
							// }, {
							// 	name: "55-65岁"
							// },
							],
						},
						series(arr) {
							console.log('series arr', arr)
			
							arr.forEach((sub, sub_index) => {
								sub.label = {
									show: true,
									color: '#000000',
									formatter: (params) => {
										//console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										// return value + '人'
										return `${value}人 (占比${percent} %)`
									}
								};
			
								sub.tooltip = {
									formatter: (params) => {
										console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										return `${name}: ${value}人 (占比${percent} %)`
			
									}
								}
			
							});
							return arr;
						},
					},
				}
				
				this.demo2_pie  = demo2_pie;
			},
			
			
			
			
			set_demo_pie3(infos) {
				let rows = [];
				let legend_data = []
				for(var key in infos) {
					//数据列
					let item = {
							维度: key,
							人数: infos[key]
					}
					rows.push(item)
					
					
					//图例
					legend_data.push({name: key})
				}
				
				
				let demo3_pie = {
					type: "饼状图",
					chartData: {
						columns: ["维度", "人数"],
						rows: rows || [
							// {
							// 	维度: "25-35岁",
							// 	人数: 10
							// },
							// {
							// 	维度: "35-45岁",
							// 	人数: 20
							// },
							// {
							// 	维度: "45-55岁",
							// 	人数: 30
							// },
							// {
							// 	维度: "55-65岁",
							// 	人数: 40
							// },
						],
					},
					theme: {
						line: {
							smooth: false
						}
					},
					extend_config: {
						color: [
							//
							"#1890FF",
							"#91CB74",
							"#EE6666",
							"#FAC858"
						],
						grid: {
							left: "0%",
							right: "0%",
							top: "0%",
							bottom: '0%',
						},
						legend: {
							show: true,
							bottom: 0,
							data: legend_data ||  [
							// 	{
							// 	name: "25-35岁"
							// }, {
							// 	name: "35-45岁"
							// }, {
							// 	name: "45-55岁"
							// }, {
							// 	name: "55-65岁"
							// },
							],
						},
						series(arr) {
							console.log('series arr', arr)
			
							arr.forEach((sub, sub_index) => {
								sub.label = {
									show: true,
									color: '#000000',
									formatter: (params) => {
										//console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										// return value + '人'
										return `${value}人 (占比${percent} %)`
									}
								};
			
								sub.tooltip = {
									formatter: (params) => {
										console.log('饼图1 params', params)
										let {
											name,
											value,
											percent
										} = params;
										return `${name}: ${value}人 (占比${percent} %)`
			
									}
								}
			
							});
							return arr;
						},
					},
				}
				
				this.demo3_pie  = demo3_pie;
			},


			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			get_list() {

			}
		},



	};
</script>

<style scoped lang="less">
	.sec-box {}

	.sec-title {
		display: flex;
		align-items: center;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: bold;
		font-size: 16px;
		color: #333333;

		&::before {
			margin-right: 8px;
			content: '';
			display: inline-block;
			width: 5px;
			height: 20px;
			background: #2373C8;
			border-radius: 10px 10px 10px 10px;
		}
	}

	.sec-number {
		margin-bottom: 26px;

		.panel-item {
			padding: 21px 17px;
			width: 100%;
			min-height: 486px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #E6E6E6;
			overflow: auto;

			&:last-child {
				margin-right: 0;
			}
		}
	}


	.panel-list {
		width: 100%;
		min-height: 435px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.panel-item {
			padding: 21px 17px;
			width: 100%;
			flex: 1;
			margin-right: 20px;
			min-height: 435px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #E6E6E6;
			padding-bottom: 40px;

			&:last-child {
				margin-right: 0;
			}

			.sec-ctx {
				width: 100%;
			}
		}
	}



	.sec-age-edu-year {
		width: 100%;
		min-height: 435px;
	}



	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			// background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #ac8d8d;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				// height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>